exports.components = {
  "component---src-components-marcas-brands-single-js": () => import("./../../../src/components/Marcas/BrandsSingle.js" /* webpackChunkName: "component---src-components-marcas-brands-single-js" */),
  "component---src-components-vehiculos-cars-single-js": () => import("./../../../src/components/Vehiculos/CarsSingle.js" /* webpackChunkName: "component---src-components-vehiculos-cars-single-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-index-js": () => import("./../../../src/pages/aviso-legal/index.js" /* webpackChunkName: "component---src-pages-aviso-legal-index-js" */),
  "component---src-pages-aviso-legal-privacidad-js": () => import("./../../../src/pages/aviso-legal/privacidad.js" /* webpackChunkName: "component---src-pages-aviso-legal-privacidad-js" */),
  "component---src-pages-aviso-legal-terminos-js": () => import("./../../../src/pages/aviso-legal/terminos.js" /* webpackChunkName: "component---src-pages-aviso-legal-terminos-js" */),
  "component---src-pages-cita-taller-js": () => import("./../../../src/pages/cita-taller.js" /* webpackChunkName: "component---src-pages-cita-taller-js" */),
  "component---src-pages-comparar-js": () => import("./../../../src/pages/comparar.js" /* webpackChunkName: "component---src-pages-comparar-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marcas-js": () => import("./../../../src/pages/marcas.js" /* webpackChunkName: "component---src-pages-marcas-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-test-drive-js": () => import("./../../../src/pages/test-drive.js" /* webpackChunkName: "component---src-pages-test-drive-js" */),
  "component---src-pages-vende-tu-vehiculo-js": () => import("./../../../src/pages/vende-tu-vehiculo.js" /* webpackChunkName: "component---src-pages-vende-tu-vehiculo-js" */)
}

